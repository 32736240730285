import { AuthProvider } from "./providers/AuthProvider";
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/worker.js').then((registration) => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }).catch((error) => {
      console.log('ServiceWorker registration failed: ', error);
    });
  });
}
function App({ children }) {
  return (
    <AuthProvider>
      <div className="App container">
        {children}
      </div>
    </AuthProvider>
  )
}


export default App;