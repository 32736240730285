import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { BACKEND_API, backend_api } from '../../helper/constant';

export default function SubscribeList() {
    const { handleGetCookies } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true)
    
    


    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                let userCookies = handleGetCookies('blockstarUser');
                if(!userCookies) return false;
                const formData = new FormData();
                formData.append('token', userCookies);
                const response = await backend_api.post(`get-subscribe.php` , formData);
                if (response && response.data && response.data.status === 'success') {
                    setUsers(response.data.data);
                    setLoading(false);
                }
                else {
                    toast.error(response.data.message)
                }
            } catch (error) {
                console.error('Error fetching coins:', error);
            }

        };

        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (

        <section className='mt-5'>
            <div className="py-8 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto text-center">
                    <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold">
                        Token List
                    </h1>
                </div>
            </div>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left rtl:text-right dark:text-gray-400">
                    <thead class="text-xs  uppercase  dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Id
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" class="px-6 py-3">
                               Created At
                            </th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr class="border-b dark:bg-gray-800 dark:hover:bg-gray-600">
                                <td colSpan="6" className="py-6">
                                    <div className="flex justify-center items-center">
                                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                                    </div>
                                </td>
                            </tr>
                        ) : users && users.map((items, key) => {
                            return (
                                <tr class="border-b dark:bg-gray-800 dark:hover:bg-gray-600" key={key}>
                                    <td class="px-6 py-4 flex items-center">
                                        {key + 1}
                                    </td>
                                    <td class="px-6 py-4">
                                        {items?.email}
                                    </td>
                                    <td class="px-6 py-4">
                                        {items?.created_at}
                                    </td>
                                    
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </section>

    )
}
