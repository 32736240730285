import { createBrowserRouter } from 'react-router-dom';
import BlankLayout from '../components/Layouts/BlankLayout';
import { routes } from './routes';
import localstorage from 'local-storage';
import AdminLayout from '../components/Layouts/AdminLayout';
import Layout from '../components/Layouts/Layout';

const finalRoutes = routes.map((route) => {
    let userCookies = localstorage.get('blockstarUser');
    
    return {
        ...route,
        element: route.layout === 'blank' || userCookies === null  ? <BlankLayout>{route.element}</BlankLayout> : route.layout === 'admin' ? <AdminLayout>{route.element}</AdminLayout>  :  <Layout>{route.element}</Layout> 
    };
});

const router = createBrowserRouter(finalRoutes);

export default router;