import React, { useContext, useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { backend_api } from '../../helper/constant';
import Switch from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';

export default function Users() {
    const { handleGetCookies } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [refersh,setRefersh] = useState('1')


    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                let userCookies = handleGetCookies('blockstarUser');
                if(!userCookies) return false;
                const formData = new FormData();
                formData.append('token', userCookies);
                formData.append('type', 'get');
                const response = await backend_api.post(`get-users.php?page_id=${page}`, formData);
                if (response && response.data && response.data.status === 'success') {
                    setUsers(response.data.data.data);
                    setLoading(false);
                }
                else {
                    toast.error(response.data.message)
                }
            } catch (error) {
                console.error('Error fetching coins:', error);
            }

        };

        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page,refersh]);


    const handleUpdateStatus = async(status , id) => {
        try {
            let userCookies = handleGetCookies('blockstarUser');
            if(!userCookies) return false;
            const formData = new FormData();
            formData.append('token', userCookies);
            formData.append('type', 'update');
            formData.append('status', status === 1 ? 2 : 1);
            formData.append('user_id' ,id)
            const response = await backend_api.post(`get-users.php`, formData);
            if (response && response.data && response.data.status === 'success') {
                setRefersh(Math.random())
            }
            else {
                toast.error(response.data.message)
            }
        } catch (error) {
            console.error('Error fetching coins:', error);
        }

    }


    return (

        <section className='mt-5'>
            <div className="py-8 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto text-center">
                    <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold">
                        Users
                    </h1>
                </div>
            </div>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left rtl:text-right dark:text-gray-400">
                    <thead class="text-xs  uppercase  dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                User ID
                            </th>
                            <th scope="col" class="px-6 py-3">
                                First Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Last Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr class="border-b dark:bg-gray-800 dark:hover:bg-gray-600">
                                <td colSpan="6" className="py-6">
                                    <div className="flex justify-center items-center">
                                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                                    </div>
                                </td>
                            </tr>
                        ) : users && users.map((items, key) => {
                            return (
                                <tr class="border-b dark:bg-gray-800 dark:hover:bg-gray-600" key={key}>
                                    <td class="w-4 p-4">
                                        {items?.id}
                                    </td>
                                    <th scope="row" class="px-6 py-4 font-medium  whitespace-nowrap dark:text-white">
                                        {items?.first_name}
                                    </th>
                                    <td class="px-6 py-4">
                                        {items?.last_name}
                                    </td>
                                    <td class="px-6 py-4">
                                        {items?.email}
                                    </td>
                                    <td class="px-6 py-4">
                                        <Switch
                                            color={items.status && items.status === 1 ? 'success' : 'danger'}
                                            checked={items.status && items.status === 1 ? true : false}
                                            onClick={() => handleUpdateStatus(items.status , items.id)}
                                            slotProps={{
                                                track: {
                                                    children: (
                                                        <React.Fragment>
                                                            <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                                                On
                                                            </Typography>
                                                            <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                                                Off
                                                            </Typography>
                                                        </React.Fragment>
                                                    ),
                                                },
                                            }}
                                            sx={{
                                                '--Switch-thumbSize': '27px',
                                                '--Switch-trackWidth': '64px',
                                                '--Switch-trackHeight': '31px',
                                            }}
                                        />
                                    </td>
                                    {/* <td class="px-6 py-4">
                                        <button type='button'>
                                            <DeleteIcon fontSize="small" className='text-red-600' />
                                        </button>
                                    </td> */}
                                </tr>
                            )
                        })}




                    </tbody>
                </table>
            </div>
        </section>

    )
}
