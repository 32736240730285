// MenuContext.js
import React, { createContext, useEffect, useState } from 'react';

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
    const [theme, setTheme] = useState(() => {
        // Initialize state from localStorage, defaulting to 'light' if not found
        return localStorage.getItem('theme') || 'dark';
    });

    useEffect(() => {
        // Update localStorage whenever the theme changes
        localStorage.setItem('theme', theme);
        if (theme === 'dark') {
            document.body.classList.add('black-theme');
        } else {
            document.body.classList.remove('black-theme');
        }
    }, [theme]);

    const toggleTheme = () => {
        
        setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
    };


    return (
        <GlobalContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalProvider };
