import React, { useEffect, useState } from 'react';
import { BACKEND_API, backend_api, BACKEND_NEW_API } from '../helper/constant';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function SearchModal({ show, setShow }) {
  const [coins, setCoins] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function fetch() {
      try {
        const response = await backend_api.get(`get-coin.php?search=${search}&featured=ignore`);
        if (response && response.data && response.data.status === 'success') {
          setCoins(response.data.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
        toast.error(error.message);
      }
    }

    if (search) {
      fetch();
    } else {
      setCoins([]);
    }
  }, [search]);

  return (
    <div id="modal" className={`inset-0 bg-opacity-50 ${show ? 'flex fixed' : 'hidden'} items-center justify-center`}>
      <div className="rounded-lg shadow-lg w-80 md:w-1/2 lg:w-1/3 bg-dark p-4">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-md font-bold">Search Coin</h2>
          <button type='button' onClick={() => setShow(false)} className="text-gray-500 hover:text-gray-700">
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div className="p-5">
          <form id="add-coin-form" className="max-w-md mx-auto mb-4">
            <div className="grid md:grid-cols-1 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                />
                <label
                  htmlFor="search"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 active"
                >
                  Search Coin Name Or Symbol
                </label>
              </div>
            </div>
          </form>
          <div className="max-h-64 overflow-y-auto">
            {coins.length > 0 ? (
              <ul className="space-y-4">
                {coins.map((coin) => (
                  <li key={coin.id} className="p-2 border rounded-lg bg-gray-800 text-white">
                    <Link to={`/coin/${coin.symbol}`} className="flex items-center">
                      <img height="30px" width="30px" src={`${BACKEND_API}upload/images/coins/${coin.logo}`} alt={coin.name} title={coin.name} />
                      <span className="font-bold mx-2">{coin.name}</span>
                      <span>({coin.symbol})</span>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              search && <p className="text-gray-500">No coins found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
