import React from 'react';

const Adspolicy = () => {
  return (
    <div className="container adsprivacy mt-10">
      <h3 className='text-center mb-10'>Blockstar Markets Ad Policy</h3>
      <p>Welcome to Blockstar Markets, your premier destination for cryptocurrency market data and insights. Our Advertising Policy supports a transparent, safe, and effective advertising environment. All advertisers must adhere to these guidelines to maintain the integrity and user experience of Blockstar Markets.</p>
      <h2>Prohibited Content</h2>
      <p>The following types of ads are strictly prohibited on Blockstar Markets:</p>
      <ol>
        <li>Ads promoting illegal activities or products</li>
        <li>Ads that mislead or deceive users</li>
        <li>Ads involving illicit or unsanctioned financial products and services</li>
        <li>Ads for products and services that harm user safety or exploit user trust</li>
      </ol>
      <p>Examples of Prohibited Ads:</p>
      <ol>
        <li>Unregulated financial products</li>
        <li>Malware or spyware</li>
        <li>Excessively vague or exaggerated claims</li>
        <li>Ads promoting discrimination, hate speech, or sexual content</li>
      </ol>
      <h2>Destination Requirements</h2>
      <p>Ad destinations must provide a seamless and positive user experience. They should:</p>
      <ol>
        <li>Offer clear and relevant content</li>
        <li>Be fully functional and not under construction</li>
        <li>Match the URL displayed in the ad</li>
        <li>Be viewable in commonly used browsers and not disable the browser’s back button</li>
      </ol>
      <h2>Technical Requirements</h2>
      <p>Ad creatives must meet certain technical standards to ensure clarity and functionality:</p>
      <ol>
        <li>Maintain appropriate aspect ratios</li>
        <li>Adhere to character limits and other format-specific constraints</li>
        <li>Contain clear imagery and text</li>
      </ol>
      <h3>Ad Dimensions</h3>
      <ol>
        <li>Header: 9:1 aspect ratio</li>
        <li>Sidebar: 4:3 aspect ratio</li>
        <li>Footer: 9:1 aspect ratio</li>
      </ol>
      <p>Ads are accepted in PNG, GIF, and JPEG format.</p>
      <h2>Compliance and Safety</h2>
      <p>All advertisers must comply with applicable laws and regulations. Repeated or serious violations may result in the restriction or suspension of advertising privileges on Blockstar Markets.</p>
      <h2>Protecting Our Users</h2>
      <p>To maintain the integrity of our platform, Blockstar Markets reserves the right to refuse, limit, or stop ads that may pose a risk to user safety or negatively impact user experience, even if the ads meet the above criteria.</p>
      <h2>Review and Approval Process</h2>
      <p>All advertisements are subject to review and approval by the Blockstar Markets team. We reserve the right to reject or remove any ad that does not comply with this policy or our platform values. Advertisement reviews can take up to 72 hours.</p>
      <h2>Refund Policy</h2>
      <p>All purchased ads are non-refundable except where Blockstar Markets rejects the advertiser. No exceptions.</p>
      <h2>Changes to Policy</h2>
      <p>Blockstar Markets reserves the right to amend or update this Advertising Policy at any time. Advertisers are encouraged to review the policy periodically to ensure compliance.</p>
    </div>
  );
};

export default Adspolicy;
