import React from 'react'

export default function Termofservice() {
    return (
        <div className="container termofservices mt-10">
            <h3 className='text-center mb-10'>Terms of Service</h3>
            <p>Welcome to BlockStar Markets. These Terms of Service ("Terms") govern your use of our website, platform, app and all related services (collectively, the "Services") provided by BlockStar Markets ("BlockStar," "we," "us," or "our"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Services.</p>
            <h4>1. Acceptance of Terms</h4>
            <p>By accessing and using the Services, you affirm that you are of legal age in your respective jurisdiction. The Service is strictly for your personal, non-commercial use unless you enter into a separate agreement with us for your commercial use. If you are using the Services on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms. You must not use the Services if we have terminated any account of yours or banned you from use of the platform or the Services.</p>
            <h4>2. Privacy Policy</h4>
            <p>Your use of the Services is also governed by our Privacy Policy, which is incorporated into these Terms and can be found on the BlockStar Markets platform. Please review our Privacy Policy to understand how we collect, use, and protect your information.</p>
            <h4>3. Account Registration</h4>
            <p>To access certain features of the Services, you may be required to register for an account ("Account"). When you register for an Account, you must provide accurate and complete information. You are solely responsible for maintaining the confidentiality of your Account credentials and for all activities that occur under your Account. We will not be responsible in any way if your password and/or Account are misappropriated or used by a third party. You agree to notify us immediately of any unauthorized use of your Account. You shall not lend, transfer or sell your Account or Account information to another party and must not use another user's Account without their written permission.</p>
            <h4>4. Prohibited Conduct</h4>
            <p>You agree not to engage in any of the following prohibited activities:</p>
            <ol>
                <li>Violating any applicable laws or regulations.</li>
                <li>Infringing on the intellectual property rights of others.</li>
                <li>Using the Services for any fraudulent or deceptive activities.</li>
                <li>Interfering with or disrupting the integrity or performance of the Services.</li>
                <li>Attempting to gain unauthorized access to the Services or related systems.</li>
            </ol>
            <h4>5. Intellectual Property</h4>
            <p>
                All content and materials available through the Services, including but not limited to text, graphics, logos, and software, are the property of BlockStar or its licensors and are protected by applicable intellectual property laws. You may not use, reproduce, modify, or distribute any content from the Services without our prior written consent.
            </p>

            <h4>6. Limitation of Liability</h4>
            <p>
                To the maximum extent permitted by law, BlockStar shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of or inability to use the Services.
            </p>

            <h4>7. Indemnification</h4>
            <p>
                You agree to indemnify, defend, and hold harmless BlockStar, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the Services or violation of these Terms.
            </p>

            <h4>8. Termination</h4>
            <p>
                We may terminate or suspend your access to the Services at any time, with or without cause or notice, and without liability to you. Upon termination, your right to use the Services will immediately cease.
            </p>

            <h4>9. Modifications to Terms</h4>
            <p>
                We reserve the right to modify these Terms at any time. We will notify you of any material changes by posting the new Terms on our website. Your continued use of the Services after the changes become effective constitutes your acceptance of the modified Terms. You should check these Terms often to make certain that you are aware of the most current Terms.
            </p>

            <h4>10. Governing Law</h4>
            <p>
                These Terms shall be governed by and construed in accordance with the applicable laws of
                Jurisdiction where as BlockStar LLC  corporate office, without regard to its conflict of law principles. Any disputes arising from these Terms or
                the Services shall be resolved exclusively via arbitration in the jurisdiction of BlockStar LLC.
            </p>

            <p className='term-m'>
                The content on the Services and the platform is strictly for informational purposes only. Nothing on or in the Services shall constitute or be construed as an offering of any currency, security, or any financial instrument or as investment advice or investment recommendations (such as recommendations as to whether to purchase a currency, security, or instrument) by BlockStar or a recommendation as to an investment strategy by BlockStar. Content on the Services should not be considered as information sufficient upon which to base an investment strategy. No content on the Services is tailored to the specific needs of any individual, entity, or group of individuals. BlockStar expresses no opinion as to the future or expected value of any currency, security, or other interest. BlockStar does not explicitly or implicitly recommend or suggest any investment strategy of any kind. Content on the Services may not be used as a basis for any financial product or other product without the express prior written consent of BlockStar.
            </p>

            <p className='term-p'>
                The content provided on the Services is submitted to BlockStar Markets by unrelated third-party providers. BlockStar Markets does not review all content for accuracy, does not review content for completeness or reliability, and does not warrant or guarantee the accuracy, completeness, reliability, or any other aspect of any content.
            </p>


        </div>
    )
}
