import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { BACKEND_API, backend_api, BACKEND_NEW_API } from '../../helper/constant';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import TokenInfoModal from './TokenInfoModal';

export default function TokenList() {
    const { handleGetCookies } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [refersh, setRefersh] = useState('1');
    const [show, setShow] = useState(false)
    const [selectedCoin, setSelectedCoin] = useState({})


    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                let userCookies = handleGetCookies('blockstarUser');
                if(!userCookies) return false;
                const formData = new FormData();
                formData.append('token', userCookies);
                formData.append('type', 'get');
                const response = await backend_api.post(`get-token-list.php?page_id=${page}`, formData);
                if (response && response.data && response.data.status === 'success') {
                    setUsers(response.data.data.data);
                    setLoading(false);
                }
                else {
                    toast.error(response.data.message)
                }
            } catch (error) {
                console.error('Error fetching coins:', error);
            }

        };

        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, refersh]);




    return (

        <section className='mt-5'>
            <div className="py-8 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto text-center">
                    <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold">
                        Token List
                    </h1>
                </div>
            </div>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left rtl:text-right dark:text-gray-400">
                    <thead class="text-xs  uppercase  dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Coin
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Contract Address
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Total Supply
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr class="border-b dark:bg-gray-800 dark:hover:bg-gray-600">
                                <td colSpan="6" className="py-6">
                                    <div className="flex justify-center items-center">
                                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                                    </div>
                                </td>
                            </tr>
                        ) : users && users.map((items, key) => {
                            return (
                                <tr class="border-b dark:bg-gray-800 dark:hover:bg-gray-600" key={key}>
                                    <td class="px-6 py-4 flex items-center">
                                        <img className='rounded-full mx-2' src={`${BACKEND_API}upload/images/coins/${items.logo}`} height="60px" width="60px" alt='logo' />
                                        {items?.name}({items?.symbol})
                                    </td>
                                    <td class="px-6 py-4">
                                        {items?.contract_address}
                                    </td>
                                    <td class="px-6 py-4">
                                        {items?.total_supply}
                                    </td>
                                    <td class="px-6 py-4">
                                        {items.is_custom === 5 ? (
                                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Rejected</span>
                                        ) : items.is_custom === 4 ? (
                                            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Approved</span>
                                        ) : (
                                            <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Pending</span>

                                        )}
                                    </td>
                                    <td class="px-6 py-4">
                                        <button onClick={() => {
                                            setShow(true)
                                            setSelectedCoin(items)
                                        }} type='button' >
                                            <BorderColorIcon className='text-green-500' />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <TokenInfoModal show={show} setShow={setShow} selectedCoin={selectedCoin} />
        </section>

    )
}
