import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { backend_api } from '../../helper/constant';

export default function TokenInfoModal({ show, setShow, selectedCoin }) {
    const { handleGetCookies } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        coin_name: '',
        coin_symbol: '',
        message: '',
        website_url: '',
        whitepaper_link: '',
        contract_address: '',
        contract_decimal_places: '',
        total_supply: '',
        blockstar_profile_link: '',
        twitter_profile_link: '',
        telegram_channel_link: '',
        facebook_profile_link: '',
        youtube_channel_link: '',
        medium_url: '',
        status: 3
    });


    useEffect(() => {
        setFormData({
            coin_name: selectedCoin?.name,
            coin_symbol: selectedCoin?.symbol,
            message: selectedCoin?.description,
            website_url: selectedCoin?.website,
            whitepaper_link: selectedCoin?.whitepaper_link,
            contract_address: selectedCoin?.contract_address,
            contract_decimal_places: selectedCoin?.contract_decimal_places,
            total_supply: selectedCoin?.total_supply,
            blockstar_profile_link: selectedCoin?.blockstar_profile_link,
            twitter_profile_link: selectedCoin?.twitter_profile_link,
            telegram_channel_link: selectedCoin?.telegram_channel_link,
            facebook_profile_link: selectedCoin?.facebook_profile_link,
            youtube_channel_link: selectedCoin?.youtube_channel_link,
            medium_url: selectedCoin?.medium_url,
            status: selectedCoin?.is_custom
        })
    }, [show])

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate required fields
        const requiredFields = ['coin_name', 'coin_symbol', 'contract_address', 'contract_decimal_places', 'total_supply'];
        for (const field of requiredFields) {
            if (!formData[field]) {
                toast.error(`${field} cannot be blank.`);
                return;
            }
        }
        let userCookies = handleGetCookies('blockstarUser');
        if(!userCookies) return false;
        const postData = new FormData();
        postData.append('token', userCookies);
        for (const key in formData) {
            postData.append(key, formData[key]);
        }

        postData.append('id' , selectedCoin.id);


        // Send POST request
        try {
            const response = await backend_api.post('edit-new-coin.php', postData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response && response.data && response.data.status === 'success') {
                toast.success('Successfully added coin');
                window.location.reload();
            } else {
                toast.error('Failed to add coin. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to add coin. Please try again later.');
        }
    };

    return (
        <div id="modal" className={`fixed inset-0 bg-opacity-50 ${show ? 'flex' : 'hidden'} items-center justify-center`}>
            <div className="relative rounded-lg shadow-lg w-full md:w-1/2 lg:w-1/2 bg-dark max-h-screen overflow-y-auto">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-md font-bold">Token Info</h2>
                    <button type='button' onClick={() => setShow(false)} className="text-gray-500 hover:text-gray-700">
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                <div className="p-4">
                    <form className="bg-theme-dark rounded shadow-lg p-4 px-4 md:p-8 mb-6" onSubmit={handleSubmit}>
                        <div className="grid md:grid-cols-2 md:gap-6 mb-5">
                            <div className="md:col-span-3">
                                <label htmlFor="coin_name">Coin Name<span className='text-red-500'>*</span></label>
                                <input type="text" name="coin_name" id="coin_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.coin_name} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-2">
                                <label htmlFor="coin_symbol">Coin Symbol<span className='text-red-500'>*</span></label>
                                <input type="text" name="coin_symbol" id="coin_symbol" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.coin_symbol} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-5">
                                <label htmlFor="message" className="block mb-2 text-sm font-medium dark:text-white">Your message</label>
                                <textarea id="message" name="message" rows="5" className="border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:border-blue-500 dark:focus:ring-blue-500 dark:placeholder-gray-400 dark:text-white focus:border-blue-500 focus:ring-blue-500 rounded-lg text-sm" placeholder="Leave a comment..." value={formData.message} onChange={handleChange}></textarea>
                            </div>
                            <div className="md:col-span-3">
                                <label htmlFor="website_url">Website URL</label>
                                <input type="text" name="website_url" id="website_url" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.website_url} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-2">
                                <label htmlFor="whitepaper_link">Whitepaper Link</label>
                                <input type="text" name="whitepaper_link" id="whitepaper_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.whitepaper_link} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-4">
                                <label htmlFor="contract_address">Address<span className='text-red-500'>*</span></label>
                                <input type="text" name="contract_address" id="contract_address" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.contract_address} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-1">
                                <label htmlFor="contract_decimal_places">Decimal<span className='text-red-500'>*</span></label>
                                <input type="text" name="contract_decimal_places" id="contract_decimal_places" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.contract_decimal_places} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-5">
                                <label htmlFor="total_supply">Total Supply<span className='text-red-500'>*</span></label>
                                <input type="text" name="total_supply" id="total_supply" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.total_supply} onChange={handleChange} />
                            </div>


                            <div className="md:col-span-2">
                                <label htmlFor="blockstar_profile_link">BlockStar Link</label>
                                <input type="text" name="blockstar_profile_link" id="blockstar_profile_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.blockstar_profile_link} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-3">
                                <label htmlFor="twitter_profile_link">Twitter Link</label>
                                <input type="text" name="twitter_profile_link" id="twitter_profile_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.twitter_profile_link} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-2">
                                <label htmlFor="telegram_channel_link">Telegram Link</label>
                                <input type="text" name="telegram_channel_link" id="telegram_channel_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.telegram_channel_link} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-3">
                                <label htmlFor="facebook_profile_link">Facebook Link</label>
                                <input type="text" name="facebook_profile_link" id="facebook_profile_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.facebook_profile_link} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-2">
                                <label htmlFor="youtube_channel_link">YouTube Link</label>
                                <input type="text" name="youtube_channel_link" id="youtube_channel_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.youtube_channel_link} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-3">
                                <label htmlFor="medium_url">Medium URL</label>
                                <input type="text" name="medium_url" id="medium_url" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.medium_url} onChange={handleChange} />
                            </div>
                            <div className="md:col-span-5">
                                <label for="status" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
                                <select onChange={handleChange} name='status' id="status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="3" selected={formData.status === '3'}>Pending</option>
                                    <option value="4" selected={formData.status === '4'}>Approved</option>
                                    <option value="5" selected={formData.status === '5'}>Rejected</option>
                                    
                                </select>
                            </div>
                        </div>






                        <div className="flex justify-center items-center mb-4">
                            <button
                                type="submit"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
