import React, { useState } from 'react'
import { backend_api } from '../helper/constant';
import logoImg from '../img/logo-udf.png';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Footer() {
    const [email, setEmail] = useState('');
    const [menuopen, setMenuopen] = useState({
        resources: false,
        support: false,
        about: false,
        community: false
    })


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };


    const handleSubmit = async () => {
        try {
            if (!validateEmail(email)) {
                toast.error('Please enter a valid email address.');
                return;
            }
            const formData = new FormData();
            formData.append('email', email);
            const response = await backend_api.post('add-subscribe.php', formData);
            if (response && response.data && response.data.status === 'success') {
                toast.success('Subscription successful!');
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Subscription failed. Please try again.');
        }
    };




    return (
        <>
            <div>
                <div class="flex gap-x-2 items-center  dark:text-moon-200 text-sm">
                    <div class="w-full border-t border-gray-100"></div>
                </div>
                <div class="container block lg:flex items-center my-6" id="footer-links">
                    <div class="lg:grid lg:grid-cols-7 lg:gap-x-8" data-controller="footer">
                        <div class="lg:col-span-3">
                            <a class="inline-block h-12" href="#sec">
                                <img alt="CoinGecko" class="h-full block dark:hidden" src={logoImg} />
                                <img alt="CoinGecko" class="h-full hidden dark:block" src={logoImg} />
                            </a>
                            <div class="mt-5 font-normal  dark:text-moon-200 text-sm leading-5">
                                BlockStar Markets provides access to real-time quotes, interactive charts, trades, breaking news, and social media posts for thousands of digital assets. Additional tools include portfolio tracking for current values and profit/loss percentages, and an easy-to-use cryptocurrency conversion calculator.
                            </div>
                        </div>


                        <div class="flex flex-col lg:space-y-4">
                            <div onClick={() => setMenuopen({ ...menuopen, resources: !menuopen.resources })} class="py-4 lg:py-0 flex lg:block items-center cursor-pointer lg:cursor-auto border-0 border-b lg:border-b-0 border-solid border-gray-200 dark:border-white dark:border-opacity-12" data-action="click->footer#toggleFooterGroup" id="footer-links-support">
                                <div class="flex-1  dark:text-moon-100 font-semibold text-sm leading-5">
                                    Resources
                                </div>
                                <span className="lg:hidden">
                                    <i
                                        className={`far fa-fw ${menuopen.resources ? 'fa-circle-down' : 'fa-plus'
                                            } text-lg dark:text-moon-50`}
                                    ></i>
                                </span>
                            </div>
                            <div class={`flex flex-col gap-y-4 mt-0 lg:mt-2 ${menuopen.resources ? '' : 'overflow-y-hidden h-0'} lg:h-auto overflow-y-hidden pl-2 lg:pl-0 lg:pb-0 border-0 lg:border-b-0 border-solid border-gray-200 dark:border-white dark:border-opacity-12`} id="footer-links-support-group">
                                <a href="#sec">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Crypto News
                                    </div>
                                </a>
                                <Link to="/risers">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Top Gainers
                                    </div>
                                </Link>
                                <Link to="/fallers">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Top Losers
                                    </div>
                                </Link>
                            </div>
                        </div>


                        <div class="flex flex-col lg:space-y-4">
                            <div onClick={() => setMenuopen({ ...menuopen, support: !menuopen.support })} class="py-4 lg:py-0 flex lg:block items-center cursor-pointer lg:cursor-auto border-0 border-b lg:border-b-0 border-solid border-gray-200 dark:border-white dark:border-opacity-12" data-action="click->footer#toggleFooterGroup" id="footer-links-support">
                                <div class="flex-1  dark:text-moon-100 font-semibold text-sm leading-5">
                                    Support
                                </div>
                                <span className="lg:hidden">
                                    <i
                                        className={`far fa-fw ${menuopen.support ? 'fa-circle-down' : 'fa-plus'
                                            } text-lg dark:text-moon-50`}
                                    ></i>
                                </span>
                            </div>
                            <div class={`flex flex-col gap-y-4 mt-0 lg:mt-2 ${menuopen.support ? '' : 'overflow-y-hidden h-0'} lg:h-auto overflow-y-hidden pl-2 lg:pl-0 lg:pb-0 border-0 lg:border-b-0 border-solid border-gray-200 dark:border-white dark:border-opacity-12`} id="footer-links-support-group">
                                <Link to="/request-form/tokens/new">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Request Form
                                    </div>
                                </Link>
                                <a href="#sec">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Advertising
                                    </div>
                                </a>
                                <a href="#sec">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Help Center
                                    </div>
                                </a>

                                <Link to="/faqs">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        FAQ
                                    </div>
                                </Link>
                            </div>
                        </div>


                        <div class="flex flex-col lg:space-y-4">
                            <div onClick={() => setMenuopen({ ...menuopen, about: !menuopen.about })} class="py-4 lg:py-0 flex lg:block items-center cursor-pointer lg:cursor-auto border-0 border-b lg:border-b-0 border-solid border-gray-200 dark:border-white dark:border-opacity-12" data-action="click->footer#toggleFooterGroup" id="footer-links-about-coingecko">
                                <div class="flex-1  dark:text-moon-100 font-semibold text-sm leading-5">
                                    About
                                </div>
                                <span class="lg:hidden">
                                    <i
                                        className={`far fa-fw ${menuopen.about ? 'fa-circle-down' : 'fa-plus'
                                            } text-lg dark:text-moon-50`}
                                    ></i>
                                </span>
                            </div>
                            <div class={`flex flex-col gap-y-4 mt-0 lg:mt-2 ${menuopen.about ? '' : 'overflow-y-hidden h-0'} lg:h-auto overflow-y-hidden pl-2 lg:pl-0 lg:pb-0 border-0 lg:border-b-0 border-solid border-gray-200 dark:border-white dark:border-opacity-12`} id="footer-links-support-group">
                                <a href="https://blockstar.site/about-us" target="_blank" rel="noreferrer">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        About Us
                                    </div>
                                </a>

                                <a href="https://blockstar.site/BlockStar-Zipped.zip" download target="_blank" rel="noreferrer">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Branding Guide
                                    </div>
                                </a>
                                <Link to="/term-of-service">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Terms of Service
                                    </div>
                                </Link>
                                <a href="#sec">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Privacy Policy
                                    </div>
                                </a>
                                <Link to="/ads-policy">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Ads Policy
                                    </div>
                                </Link>
                            </div>
                        </div>


                        <div class="flex flex-col lg:space-y-4">
                            <div onClick={() => setMenuopen({ ...menuopen, community: !menuopen.community })} class="py-4 lg:py-0 flex lg:block items-center cursor-pointer lg:cursor-auto border-0 border-b lg:border-b-0 border-solid border-gray-200 dark:border-white dark:border-opacity-12" data-action="click->footer#toggleFooterGroup" id="footer-links-learn">
                                <div class="flex-1  dark:text-moon-100 font-semibold text-sm leading-5">
                                    Community
                                </div>
                                <span class="lg:hidden">
                                    <i
                                        className={`far fa-fw ${menuopen.community ? 'fa-circle-down' : 'fa-plus'
                                            } text-lg dark:text-moon-50`}
                                    ></i>
                                </span>
                            </div>
                            <div class={`flex flex-col gap-y-4 mt-0 lg:mt-2 ${menuopen.community ? '' : 'overflow-y-hidden h-0'} lg:h-auto overflow-y-hidden pl-2 lg:pl-0 lg:pb-0 border-0 lg:border-b-0 border-solid border-gray-200 dark:border-white dark:border-opacity-12`} id="footer-links-support-group">
                                <a href="https://blockstar.social/BlockStar_Official" target="_blank" rel="noreferrer">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        BlockStar
                                    </div>
                                </a>
                                <a href="https://blockstar.chat/" target="_blank" rel="noreferrer">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        BlockStar Chat
                                    </div>
                                </a>
                                <a href="https://x.com/BlockStar_BST" target="_blank" rel="noreferrer">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        X/Twitter
                                    </div>
                                </a>
                                <a href="https://t.me/BlockStar_Announcements" target="_blank" rel="noreferrer">
                                    <div class="font-normal  dark:text-moon-200 text-sm leading-5">
                                        Telegram
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex gap-x-2 items-center  dark:text-moon-200 text-sm">
                    <div class="w-full border-t border-gray-100"></div>
                </div>

                <div class="container block lg:flex items-center my-6" id="footer-newsletter" data-controller="newsletter">
                    <div class="block lg:flex-1">
                        <div data-view-component="true" class="mb-2  dark:text-moon-100 font-semibold text-sm leading-5">
                            Interested to stay up-to-date with cryptocurrencies?
                        </div>
                        <div data-view-component="true" class="mb-2 lg:mb-0 font-normal  dark:text-moon-200 text-sm leading-5">
                            Get the latest crypto news, updates, and reports by subscribing to our free newsletter.
                        </div>
                    </div>
                    <div>
                        <form class="block lg:flex justify-end mb-1">
                            <div class="mb-2 lg:mb-0 lg:mr-2">
                                <div class="px-0.5">
                                    <div id="_container" x-ref="container" class="gecko-input-group">
                                        <input value={email} onChange={handleEmailChange} placeholder="Enter your email address" type="email" class="h-10 gecko-input" />
                                    </div>
                                    <div x-ref="error" class="mt-2 font-semibold text-danger-500 hidden text-xs leading-4  dark:text-moon-200">
                                    </div>
                                </div>
                            </div>
                            <div>

                                <button onClick={() => handleSubmit()} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                    Subscribe
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="flex gap-x-2 items-center  dark:text-moon-200 text-sm">
                    <div class="w-full border-t border-gray-100"></div>
                </div>

                <div class="container block lg:flex items-center my-6 text-center lg:text-left" id="footer-copyright">
                    <div class="block lg:flex-1 mb-4 lg:mb-0">
                        <div data-view-component="true" class="font-normal  dark:text-moon-200 text-sm leading-5">
                            © 2024 BlockStar. All Rights Reserved.
                        </div>
                    </div>
                </div>

                <div class="container my-6" id="footer-disclaimer">
                    <span class="font-bold cursor-pointer font-semibold underline hover:text-primary-500 hover:underline dark:text-slate-50 dark:hover:text-primary-400">
                        IMPORTANT DISCLAIMER: {" "}
                    </span>
                    <div data-view-component="true" class="inline font-normal  dark:text-moon-200 text-sm leading-5">
                        All content provided on our website, including hyperlinked
                        sites, associated applications, forums, blogs, social media accounts, and other
                        platforms (collectively referred to as the "Site"), is intended for general informational
                        purposes only. This information is sourced from third-party providers. We do not offer
                        any warranties regarding the accuracy, completeness, or timeliness of our content.
                        None of the information provided on this site constitutes financial advice, legal advice,
                        or any other type of professional consulting intended for your specific reliance. Any use
                        of or reliance on our content is solely at your own risk. We strongly advise that you
                        conduct your own research and thoroughly review, analyze, and verify the content
                        before relying on it for any purpose.
                        Trading and other financial activities carry significant risk and can result in substantial
                        losses. Therefore, we advise consulting your financial advisor before making any
                        decisions. Additionally, no part of our content is intended to be a solicitation or offer.
                    </div>
                </div>
            </div>
        </>
    )
}
