import { lazy } from 'react';
import Users from '../pages/Admin/Users';
import TokenList from '../pages/Admin/TokenList';
import SubscribeList from '../pages/Admin/SubscribeList';
import Adspolicy from '../pages/Adspolicy';
import Termofservice from '../pages/Termofservice';

// Lazy load all components
const Home = lazy(() => import('../pages/Home'));
const Fallers = lazy(() => import('../pages/Fallers'));
const Risers = lazy(() => import('../pages/Risers'));
const CoinDetails = lazy(() => import('../pages/CoinDetails'));
const Login = lazy(() => import('../pages/Login'));
const SIgnup = lazy(() => import('../pages/SIgnup'));
const Favorite = lazy(() => import('../pages/Favorite'));
const PortfolioCoinDetails = lazy(() => import('../pages/PortfolioCoinDetails'));
const Faqs = lazy(() => import('../pages/Faqs'));
const NewTokenList = lazy(() => import('../pages/NewTokenList'));
const Calculator = lazy(() => import('../pages/Calculator'));

const routes = [
    {
        path: '/',
        element: <Home />,
        
    },
    {
        path: '/risers',
        element: <Risers />,
    },
    {
        path: '/fallers',
        element: <Fallers />,
    },
    {
        path: '/coin/:id',
        element: <CoinDetails />,
    },
    {
        path: '/favorites',
        element: <Favorite />,
    },
    {
        path: '/favorites/:id',
        element: <PortfolioCoinDetails />,
    },
    {
        path: '/faqs',
        element: <Faqs />,
    },
    {
        path: '/request-form/tokens/new',
        element: <NewTokenList />,
    },
    {
        path: '/calculator',
        element: <Calculator />,
    },
    {
        path: '/ads-policy',
        element: <Adspolicy />,
    },
    {
        path: '/term-of-service',
        element: <Termofservice />,
    },
    {
        path: '/login',
        element: <Login />,
        layout : 'blank'
    },
    {
        path: '/signup',
        element: <SIgnup />,
        layout : 'blank'
    },
    {
        path: '/admin',
        element: <Users />,
        layout : 'admin'
    },
    {
        path: '/admin/token-list',
        element: <TokenList />,
        layout : 'admin'
    },
    {
        path: '/admin/subscribe-list',
        element: <SubscribeList />,
        layout : 'admin'
    },
];

export { routes };
