import React from 'react'
import App from '../../App'
import Header from '../../pages/Admin/Header'

export default function AdminLayout({ children }) {
    return (
        <App>
            <Header/>
            {children}
        
        </App>
    )
}
