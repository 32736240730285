import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import App from '../../App'

export default function Layout({ children }) {
    return (
        <App>
            <Header />
            {children}
            <Footer />
        </App>
    )
}
