import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { CryptoProvider } from './context/CryptoContext';
import { TrendingProvider } from './context/TrendingContext';
import { StorageProvider } from './context/StorageContext';
import { GlobalProvider } from './context/GlobalContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RouterProvider } from 'react-router-dom';
import router from './router/index';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense>
      <GlobalProvider>
        <CryptoProvider>
          <TrendingProvider>
            <StorageProvider>

              <ToastContainer />
              <RouterProvider router={router} />

            </StorageProvider>
          </TrendingProvider>
        </CryptoProvider>
      </GlobalProvider>
    </Suspense>


  </React.StrictMode>
);