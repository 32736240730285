import React, { useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../context/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../img/logo-udf.png';
import { AuthContext } from '../providers/AuthProvider';
import localStorage from 'local-storage';
import SearchModal from './SearchModal';
import { APP_URL } from '../helper/constant';

export default function Header() {
    const { handleCookies, user, loadingUserData } = useContext(AuthContext);
    const { toggleTheme } = useContext(GlobalContext);
    const menuRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigator = useNavigate();
    const [show, setShow] = useState(false)


    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    }


    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);


    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstallable, setIsInstallable] = useState(false);

    useEffect(() => {
        const handler = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
            setIsInstallable(true);
        }

        window.addEventListener('beforeinstallprompt', handler);

        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        }
    }, []);

    const handleInstallClick = async () => {
        if (!deferredPrompt) {
            console.error("Install prompt not available");
            return;
        }

        // Show the install prompt
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        console.log(`User response to the install prompt: ${outcome}`);

        // Reset the deferred prompt
        setDeferredPrompt(null);
        setIsInstallable(false);
        setIsMenuOpen(false)
    };




    return (
        <>
            <header>
                <div class="primary-background">
                    <div class="darken-background">
                        <div class="row container">
                            <div id="total-cryptocurrencies" class="col s12 m4 left-align">
                                <b>7273</b> Cryptocurrencies
                            </div>
                            <div class="col s12 m4 center-align">
                                Total Market Cap <b>$3,272.41B</b>
                            </div>
                            <div id="total-volume" class="col s12 m4 right-align">
                                24h Total Volume <b>$903.70B</b>
                            </div>
                        </div>
                    </div>
                </div>

                <nav class="primary-background" role="navigation">
                    <div class="nav-wrapper container">

                        <a href={APP_URL} class="brand-logo">
                            <img src={logoImg} alt='logo' />
                        </a>
                        <ul class="right">
                            <li><a href='#sec' onClick={() => setShow(true)} id="search-coins-button"><i class="material-icons">search</i></a></li>
                            <li onClick={() => toggleTheme()}><a href="#sec"><i class="material-icons">brightness_medium</i></a></li>
                            <li><button type='button' onClick={() => toggleMenu()}><i class="material-icons">menu</i></button></li>
                        </ul>
                    </div>
                </nav>


                <ul class="dropdown-content">
                    <li>
                        <a href="#sec" class="language-code">
                            <i class="material-icons">settings</i> Settings
                        </a>
                    </li>
                </ul>

                {isMenuOpen &&
                    <ul id="side-menu" ref={menuRef} class="side-nav" style={{ "transform": "translateX(0px)" }}>
                        <li><a href="#sec" class="subheader">BlockStar</a></li>
                        <li><a href={APP_URL} onClick={()=>setIsMenuOpen(false)} class="waves-effect"><i class="material-icons">public</i>Home</a></li>
                        <li><Link to="/risers"  onClick={()=>setIsMenuOpen(false)} class="waves-effect"><i class="material-icons">trending_up</i>Top Gainers</Link></li>
                        <li><Link to="/fallers" onClick={()=>setIsMenuOpen(false)} class="waves-effect"><i class="material-icons">trending_down</i>Top Losers</Link></li>
                        <li><Link to="/favorites" onClick={()=>setIsMenuOpen(false)} class="waves-effect"><i class="material-icons">star_border</i>Favorite Coins</Link></li>
                        <li><Link to="/calculator" onClick={()=>setIsMenuOpen(false)} class="waves-effect"><i class="material-icons">compare_arrows</i>Cryptocurrency Calculator</Link></li>
                        <li><Link to="/faqs" onClick={()=>setIsMenuOpen(false)} class="waves-effect"><i class="material-icons">help_outline</i>FAQ</Link></li>
                        {!loadingUserData && user ? (
                            <li><a href="#sec" onClick={() => {
                                handleCookies();
                                localStorage.remove('theme');
                                navigator('/login');
                                setIsMenuOpen(false)


                            }} class="waves-effect"><i class="material-icons">lock_open</i>Log Out</a></li>
                        ) : (
                            <li><Link to="/login" onClick={()=>setIsMenuOpen(false)} class="waves-effect"><i class="material-icons">lock_open</i>Log in</Link></li>

                        )
                        }
                        <li><a href='#sec' onClick={() => handleInstallClick()} class="waves-effect"><i class="material-icons">translate</i>Add PWA</a></li>
                        <li class="btn-side-menu-languages-container">
                            <a href="#sec" id="btn-side-menu-languages" data-activates="side-menu-languages" class="waves-effect">
                                <i class="material-icons">translate</i>
                                <span>
                                    <img class="right mn-lang-img" src="assets/images/flags/us.png" alt='falg' />
                                    Language
                                </span>
                            </a>
                        </li>

                        <li class="btn-side-menu-currency-container">
                            <a href="#sec" id="btn-side-menu-currency" data-activates="side-menu-currency" class="waves-effect">
                                <i class="material-icons">attach_money</i>
                                <span>
                                    <span class="right mn-currency-val">USD</span>
                                    Display Currency
                                </span>
                            </a>
                        </li>

                        <li class="btn-side-menu-currency-container"><a href="#sec" id="theme-change-button" class="waves-effect" onClick={toggleTheme}><i class="material-icons">brightness_medium</i>Day / night mode</a></li>

                    </ul>
                }
            </header>
            <SearchModal show={show} setShow={setShow} />

            <nav class="mobile-bottom-nav">
                <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
                    <div class="mobile-bottom-nav__item-content">
                        <Link to="/">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fff" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"></path></svg>
                        </Link>
                    </div>
                </div>
                <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
                    <div class="mobile-bottom-nav__item-content">
                        <Link to="/favorites">
                            <i class="material-icons text-white">star_border</i>
                        </Link>
                    </div>
                </div>
                <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
                    <div class="mobile-bottom-nav__item-content">
                        <Link to="/calculator" data-load="feed">
                            <i class="material-icons text-white">compare_arrows</i>
                        </Link>
                    </div>
                </div>
                <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
                    <div class="mobile-bottom-nav__item-content">
                        <a href='#sec' onClick={() => setShow(true)} id="search-coins-button">
                            <i class="material-icons text-white">search</i>
                        </a>
                    </div>
                </div>
            </nav>
        </>
    )
}
