import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from '../../App';

const BlankLayout = ({ children }) => {
    return (
        <App>

            <ToastContainer />
            {children}

        </App>
    );
};

export default BlankLayout;