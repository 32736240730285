import React, { useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../../img/logo-udf.png';
import { AuthContext } from '../../providers/AuthProvider';
import localStorage from 'local-storage';
import { APP_URL } from '../../helper/constant';

export default function Header() {
    const { handleCookies, user, loadingUserData } = useContext(AuthContext);
    const { toggleTheme } = useContext(GlobalContext);
    const menuRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigator = useNavigate();




    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };


    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);


    return (
        <>
            <header>
                <nav class="primary-background" role="navigation">
                    <div class="nav-wrapper container">

                        <a href={APP_URL} class="brand-logo">
                            <img src={logoImg} alt='logo' />
                        </a>
                        <ul class="right">
                            <li onClick={() => toggleTheme()}><a href="#sec"><i class="material-icons">brightness_medium</i></a></li>
                            <li><button type='button' onClick={() => toggleMenu()}><i class="material-icons">menu</i></button></li>
                        </ul>
                    </div>
                </nav>


                <ul class="dropdown-content">
                    <li>
                        <a href="#sec" class="language-code">
                            <i class="material-icons">settings</i> Settings
                        </a>
                    </li>
                </ul>

                {isMenuOpen &&
                    <ul id="side-menu" ref={menuRef} class="side-nav" style={{ "transform": "translateX(0px)" }}>
                        <li><a href="#sec" class="subheader">BlockStar</a></li>
                        <li><Link to="/admin" class="waves-effect"><i class="material-icons">public</i>Users</Link></li>
                        <li><Link to="/admin/token-list" class="waves-effect"><i class="material-icons">public</i>Token List</Link></li>
                        <li><Link to="/admin/subscribe-list" class="waves-effect"><i class="material-icons">public</i>Subscribe List</Link></li>

                        {!loadingUserData && user ? (
                            <li><a href="#sec" onClick={() => {
                                handleCookies();
                                localStorage.remove('theme');
                                navigator('/login');


                            }} class="waves-effect"><i class="material-icons">lock_open</i>Log Out</a></li>
                        ) : (
                            <li><Link to="/login" class="waves-effect"><i class="material-icons">lock_open</i>Log in</Link></li>

                        )
                        }

                        <li class="btn-side-menu-languages-container">
                            <a href="#sec" id="btn-side-menu-languages" data-activates="side-menu-languages" class="waves-effect">
                                <i class="material-icons">translate</i>
                                <span>
                                    <img class="right mn-lang-img" src="assets/images/flags/us.png" alt='falg' />
                                    Language
                                </span>
                            </a>
                        </li>

                        <li class="btn-side-menu-currency-container">
                            <a href="#sec" id="btn-side-menu-currency" data-activates="side-menu-currency" class="waves-effect">
                                <i class="material-icons">attach_money</i>
                                <span>
                                    <span class="right mn-currency-val">USD</span>
                                    Display Currency
                                </span>
                            </a>
                        </li>

                        <li class="btn-side-menu-currency-container"><a href="#sec" id="theme-change-button" class="waves-effect" onClick={toggleTheme}><i class="material-icons">brightness_medium</i>Day / Night Mode</a></li>

                    </ul>
                }
            </header>
        </>
    )
}
